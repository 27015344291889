
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import axios from 'axios';
import { INVITE_URL } from '@/store/constants';
import { secret } from '@/helpers/data/fauna-queries';

interface Data {
  name: string;
  email: string;
  showMessage: boolean;
  message: TranslateResult;
  messageType: string;
  submitted: boolean;
}

interface Method {
  invite(): void;

  cleanError(): void;
}

export default Vue.extend<
  Data,
  Method,
  Record<string, never>,
  Record<string, never>
>({
  name: 'InviteUser',
  data() {
    return {
      name: '',
      email: '',
      message: '',
      messageType: 'error',
      showMessage: false,
      submitted: false
    };
  },
  methods: {
    invite() {
      this.submitted = true;
      this.showMessage = false;
      this.message = '';
      const { name, email } = this;
      if (name && email) {
        axios
          .post(INVITE_URL, { name: name, email: email, code: secret })
          .then((res) => {
            if (res) {
              this.submitted = false;
              this.messageType = 'success';
              this.showMessage = true;
              this.message = this.$t('inviteUser.success', { name: this.name });
              this.name = '';
              this.email = '';
            } else {
              this.message = this.$t('inviteUser.errorMessages.generic');
            }
          })
          .catch((error) => {
            this.submitted = false;
            this.showMessage = true;
            this.messageType = 'error';
            if (error && error.response) {
              if (error.response.status === 400) {
                this.message = this.$t(
                  'inviteUser.errorMessages.allFieldsAreMandatory'
                );
              } else if (error.response.status === 401) {
                this.message = this.$t(
                  'inviteUser.errorMessages.noPermissionToInvite'
                );
              } else if (error.response.status === 406) {
                this.message = this.$t(
                  'inviteUser.errorMessages.UserAlreadyExist'
                );
              } else if (error.response.status === 409) {
                this.message = this.$t(
                  'inviteUser.errorMessages.noPermissionToInvite'
                );
              } else if (error.response.status === 500) {
                this.message = this.$t('inviteUser.errorMessages.generic');
              }
            } else {
              this.message = this.$t('inviteUser.errorMessages.generic');
              this.$rollbar.error('Error', error);
            }
          });
      }
    },
    cleanError() {
      this.showMessage = false;
      this.message = '';
    }
  }
});
