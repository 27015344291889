
import Vue from "vue";
import { mapState } from "vuex";
import { User } from "@/store/modules/user";
import InviteUser from "@/components/user/InviteUser.vue";
import DisplayInfo from "@/components/DisplayInfo.vue";
import AvatarCard from "@/components/AvatarCard.vue";
import { mdiAccount, mdiDotsVertical } from "@mdi/js";
import { TYPE } from "@/store/modules/notification";
import UserEditCard from "@/views/UserEditCard.vue";
import { TranslateResult } from "vue-i18n";

// import PasswordChangeCard from '@/views/PasswordChangeCard.vue';

interface Languages {
  label: TranslateResult;
  value: string;
}

interface Data {
  loading: boolean;
  menu: boolean;
  dialog: boolean;
  passwordDialog: boolean;
}

interface Computed {
  user: User;
  mdiAccount: string;
  mdiDotsVertical: string;
  formatedDob: string;
  translatedLangs: Languages[];
}

interface Methods {
  openDialog(): void;
  openPasswordDialog(): void;
  changeLang(): void;
  updatedUser(): void;
  updatedPassword(): void;
}

export default Vue.extend<Data, Methods, Computed, Record<string, never>>({
  name: 'User',
  components: {
    // PasswordChangeCard,
    UserEditCard,
    AvatarCard,
    InviteUser,
    DisplayInfo
  },
  data: () => ({
    loading: false,
    menu: false,
    dialog: false,
    newPasswordDialog: false,
    passwordDialog: false
  }),
  methods: {
    openDialog() {
      this.dialog = true;
      this.menu = false;
    },
    openPasswordDialog() {
      this.passwordDialog = true;
      this.menu = false;
    },
    changeLang() {
      //TODO persist in cookies
      this.$dayjs.locale(this.$i18n.locale);
      this.$vuetify.lang.current =
        this.$i18n.locale == 'pt-br' ? 'pt' : this.$i18n.locale;
    },
    updatedUser() {
      this.dialog = false;
      this.$store.commit('NotificationModule/add', {
        msg: this.$t('user.updated-snackbar-success'),
        type: TYPE.SUCCESS
      });
    },
    updatedPassword() {
      this.passwordDialog = false;
      this.$store.commit('NotificationModule/add', {
        msg: this.$t('user.password-snackbar-success'),
        type: TYPE.SUCCESS
      });
    }
  },
  computed: {
    ...mapState('UserModule', ['user']),
    mdiAccount() {
      return mdiAccount;
    },
    mdiDotsVertical() {
      return mdiDotsVertical;
    },
    formatedDob() {
      return this.$dayjs(this.user.dob).format('L');
    },
    translatedLangs() {
      return [
        {
          label: this.$t('user.languages.pt-br'),
          value: 'pt-br'
        },
        {
          label: this.$t('user.languages.en'),
          value: 'en'
        },
        {
          label: this.$t('user.languages.es'),
          value: 'es'
        }
      ];
    }
  }
});
