
import Vue from "vue";
import DatePicker from "@/components/horse/DatePicker.vue";
import { email, or, required } from "vuelidate/lib/validators";
import { showError } from "@/helpers/utils";
import { Validation, validationMixin } from "vuelidate";
import vuelidateMixin from "@/helpers/vuelidateMixin";
import _ from "lodash";
import { TranslateResult } from "vue-i18n";
import { ValidationGroups } from "vue/types/vue";
import { cellphone, phone } from "@/helpers/validations";
import FormDialog from "@/components/FormDialog.vue";
import { User } from "@/store/modules/user";
import { mask } from "vue-the-mask";
import { mdiPhone } from "@mdi/js";

interface Data {
  loading: boolean;
  userEdit: User;
  bornDatePicker: string;
  bornDateDisplay: string;
  valid: boolean;
}

interface Methods {
  update(): void;
  cancel(): void;
  selectedDate(date: string): void;
}

interface Computed {
  formValid: boolean;
  mdiPhone: string;
  registerNameValidation: TranslateResult[];
  registerEmailValidation: TranslateResult[];
  registerPhoneValidation: TranslateResult[];
}

interface Props {
  user: User;
  dialog: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'UserEditCard',
  components: { FormDialog, DatePicker },
  mixins: [validationMixin, vuelidateMixin],
  directives: { mask },
  validations: {
    userEdit: {
      name: { required },
      phone: { required, validFormat: or(phone, cellphone) },
      email: { required, email }
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    loading: false,
    valid: false,
    userEdit: {
      id: '',
      name: '',
      dob: '',
      email: '',
      phone: '',
      avatar: '',
      freePlan: '',
      spaces: []
    },
    bornDatePicker: '',
    bornDateDisplay: ''
  }),
  async created() {
    this.userEdit = _.cloneDeep(this.user);
    this.$v.$touch();
  },
  methods: {
    update() {
      this.$v.$touch();
      const datePickerV = (this.$refs.datePicker as Validation).$v;
      datePickerV.$touch();
      if (!this.$v.$invalid && !this.$v.$pending) {
        this.loading = true;
        this.$store
          .dispatch('UserModule/updateUser', {
            user: this.userEdit
          })
          .then(() => {
            this.loading = false;
            this.$emit('user-updated');
          })
          .catch((error) => {
            showError(error);
            this.loading = false;
          });
      }
    },
    selectedDate(date) {
      this.userEdit.dob = date;
    },
    cancel() {
      this.userEdit = _.cloneDeep(this.userEdit);
      this.$emit('cancel');
    }
  },
  computed: {
    mdiPhone() {
      return mdiPhone;
    },
    formValid() {
      return !this.$v.userEdit.$invalid;
    },
    registerNameValidation() {
      const errors: TranslateResult[] = [];
      if (!this.$v.userEdit.name?.$dirty) return errors;
      !this.$v.userEdit.name.required &&
        errors.push(this.$t('login.register.errorMessages.requiredField'));
      return errors;
    },
    registerEmailValidation() {
      const errors: TranslateResult[] = [];
      if (!this.$v.userEdit.email?.$dirty) return errors;
      !this.$v.userEdit.email.required &&
        errors.push(this.$t('login.register.errorMessages.requiredField'));
      !this.$v.userEdit.email.email &&
        errors.push(this.$t('login.register.errorMessages.emailMustBeValid'));
      return errors;
    },
    registerPhoneValidation() {
      const errors: TranslateResult[] = [];
      const field = this.$v.userEdit.phone as ValidationGroups & Validation;
      if (!field.$dirty) return [];
      !field.required &&
        errors.push(this.$t('login.register.errorMessages.requiredField'));
      !field.validFormat &&
        errors.push(this.$t('login.register.errorMessages.phoneMustBeValid'));
      return errors;
    }
  }
});
